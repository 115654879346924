import React , { Fragment , useState ,useEffect} from 'react';
import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";
import UAParser from "ua-parser-js";
import WithScrollbar from "./WithScrollbar";
import WithVideo from "./WithVideo";
import Section from "./Section";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};


// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ({ deviceType }) => {

const [images, setImages] = useState([]);

 useEffect(() => {
   const eventsUrl = '/sessions/product_images' ;
  	  const requestMetadata = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
     //   body: JSON.stringify({events: this.state.events})
    };
    
   fetch(eventsUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    	  
     setImages(jsonData.data);
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
   
  }, []);


  return (
    <Carousel
      centerMode
      deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
      infinite={true}
      autoPlay={deviceType !== "mobile" ? true : false}
      autoPlaySpeed={2000}
    >
      {images.map(o => {
        return (
        	<a href={o.url} key={o.url}>
          <Image
            draggable={false}
            style={{ width: "100%", height: "100%" }}
            src={o.image_url}
          />
         </a>
        );
      })}
     
    </Carousel>
  );
};



const Index = ({ deviceType }) => {
  return (
    <Fragment>
      <Section>
        <Simple deviceType={deviceType} />
      </Section>
     
    </Fragment>
  );
};
Index.getInitialProps = ({ req }) => {
  let userAgent;
  if (req) {
    userAgent = req.headers["user-agent"];
  } else {
    userAgent = navigator.userAgent;
  }
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || "desktop";
  return { deviceType };
};
export default Index;


