import React from "react";
import { Calendar as Cal, momentLocalizer } from "react-big-calendar";
import moment from "./moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import MyWorkWeek from "./MyWorkWeek.component";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const DragAndDropCalendar = withDragAndDrop(Cal);

const Calendar = ({
  events = [],
  date = new Date(),
  onNavigate,
  view = "week",
  onView,
  views = {
    day: true,
    month: true,
    week: true,
    myweek: MyWorkWeek
  },
  getNow = () => new Date(),
  accessors,
  selectable = false,
  ...props
}) => {
  const localizer = momentLocalizer(moment);

  return (
    <DragAndDropCalendar
      {...{
        localizer,
        events,
        date,
        onNavigate,
        view,
        onView,
        views,
        getNow,
        accessors,
        selectable
      }}
      messages={{
        myweek: "My Week"
      }}
      resizable
      {...props}
    />
  );
};

export default Calendar;
