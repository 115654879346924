// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.
// App.jsx

import React from 'react';

// Import ReactDOM
import ReactDOM from 'react-dom';
import { f7ready } from 'framework7-react';

import { App, NavRight, Panel, View, Page, Navbar, Block, Row, Col, Button, Popup, Link} from 'framework7-react';
import Dom7 from 'dom7'


import {routes} from './my-app4';
var $$ = Dom7;
 
//import {routes} from './routes.js';

// Define Dom7

const f7params = {
 
  // App Name
  name: 'My App',
  // App id
  id: 'com.myapp.test',
  routes: routes,

  // ...
};



export default () => (



 
  <App {...f7params}>
   
    {/* initial page is specified in routes.js */}
    <View main url="/" />
    <div type="hidden" name="userid" id="userid" value=""></div>
    <div type="hidden" name="accessToken" id="accessToken" value=""></div>
    <div className='notice' value='成功!'></div>
    <div className='dtitle' value=''></div>
  </App>
)