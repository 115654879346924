import React, { useState ,useEffect,Children} from "react";
import ReactDOM from "react-dom";
import { f7ready } from 'framework7-react';
import Jitsi from 'react-jitsi';
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  Row,
  Col,
  ListInput,
  List,
  ListItem,
  BlockHeader,
  Button,
  Segmented,
  Icon,
  Range,
} from 'framework7-react';

const formatName = (name, count) => `${name} ID ${count}`
const now = () => new Date();
let selectTimeout;

const JitsiMeet = (props) => {
  
  const [show, toggleShow] = React.useState(false);
 

  
  
   
  return (	
    <div>
   
    
    
          <Block>
         <Row>
         
        

<Col width="400">
      <div width="400px">
      <Button fill round
        onClick={() => toggleShow(!show)}
      >
        {show ? '離開視訊' : '開啟視訊'}
      </Button>    
      </div>
  </Col>
  	<Col width="30">
         	
         		
         	</Col>
         	<Col width="30">
         	
         	</Col>
         	
 
       </Row>
         </Block>

 {show && 
 <Jitsi roomName={props.room_name ? props.room_name : "guest"}
        displayName="tc"
     //   loadingComponent={Loader}
        domain="chat.anitya.com.tw"
        jwt =  'eyJhbGciOiJIUzI1NiJ9.eyJqaWQiOiJ0Y2p1YW4yMkBjaGF0LmFuaXR5YS5jb20udHciLCJleHAiOjE2MDgzMTQ0MzJ9._Qdw8SHL-9uO-yVp1k9qKhwgnDLvIkwNavbTJh4A04U'
        onAPILoad={JitsiMeetAPI => console.log('Good Morning everyone!')} />
    }
      
  </div>
  );
};

export default JitsiMeet