export const events = [
  {
    id: 1,
    title: "Event 1",
    patient: "Kent, Clark",
    start: "2019-02-01T12:30:00Z",
    end: "2019-02-01T14:00:00Z"
  },
  {
    id: 2,
    title: "Event 2",
    patient: "Wayne, Bruce",
    start: "2019-02-02T10:00:00Z",
    end: "2019-02-02T12:15:00Z"
  },
  {
    id: 3,
    title: "Event 3",
    patient: "Curry, Arthur",
    start: "2019-02-03T12:00:00Z",
    end: "2019-02-03T12:15:00Z"
  },
  {
    id: 4,
    title: "Event 4",
    patient: "Allen, Barry",
    start: "02/04/2019 10:00",
    end: "02/04/2019 14:45"
  },
  {
    id: 5,
    title: "Event 5",
    patient: "Dibney, Ralph",
    start: "2019-02-05",
    end: "2019-02-05",
    blocked: true,
    allDay: true
  },
  {
    id: 6,
    title: "Event 6",
    patient: "Kent, Clark",
    start: "2022-03-25T12:30:00Z",
    end: "2022-03-25T20:00:00Z"
  },
  {
    id: 7,
    title: "Event 7",
    patient: "Wayne, Bruce",
    start: "2019-02-07T10:00:00Z",
    end: "2019-02-07T12:15:00Z"
  },
  {
    id: 8,
    title: "Event 8",
    patient: "Curry, Arthur",
    start: "2019-02-08T12:00:00Z",
    end: "2019-02-08T12:15:00Z"
  },
  {
    id: 9,
    title: "Event 9",
    patient: "Allen, Barry",
    start: "02/09/2019 10:00",
    end: "02/09/2019 14:45"
  },
  {
    id: 10,
    title: "Event 10",
    patient: "Dibney, Ralph",
    start: "2019-02-10",
    end: "2019-02-10",
    blocked: true,
    allDay: true
  }
];
