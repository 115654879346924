import React, { useState ,useEffect,Children} from "react";
import ReactDOM from "react-dom";
import Calendar from "./Calendar.component";
import moment from 'moment';
import { events as eventData } from "./events";
import { f7ready } from 'framework7-react';
import Card from './Card'
import Jitsi from 'react-jitsi';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  Row,
  Col,
  ListInput,
  List,
  ListItem,
  BlockHeader,
  Button,
  Segmented,
  Icon,
  Range,
} from 'framework7-react';

const formatName = (name, count) => `${name } (增加可預約日期 ， 請拖抑到日期。）`
const now = () => new Date();
let selectTimeout;

const MyCalendar = (props) => {
  const [events, setEvents] = useState(
    eventData.map((event) => {
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      event.status = event.status;
      return event;
    })
  );
  const [date, setDate] = useState(now());
  const [view, setView] = useState("month");
  const [counters, setCounters] = useState({
        '可預約-上午': 0,
        '可預約-下午': 0,
        '可預約-晚上': 0,
        '可預約-全天': 0,
      });
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [ draggedEvent, setDraggedEvent] = useState(null);
  const [ customerId, setCustomerID] = useState(null);
  const onNavigate = (newDate) => setDate(newDate);
  const onView = (newView) => setView(newView);
  const accessors = {
    draggableAccessor: (event) => !event.blocked,
    resizableAccessor: (event) => !event.blocked
  };
  const handleDragStart = (event) => {
    setDraggedEvent(event );
  }
  const handleDisplayDragItemInCell = () => {
    setDisplayDragItemInCell(!displayDragItemInCell);
  }
  
  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      title: props.title ? props.title : (props.mode == 'Consultant' ? '可預約' : '預約'),
      start,
      end,
      isAllDay: true,
    }
      console.log('on drop from outside ' + event.start);
    const updatedCounters = {
      ...counters,
      [draggedEvent.name]: counters[draggedEvent.name] + 1,
    }
    setDraggedEvent(null);
    setCounters(updatedCounters );
    
         
            event.title = props.title ? props.title : (props.mode == 'Consultant' ?  draggedEvent.name : '預約')
            if (draggedEvent.name.includes('全天')) {
            	 event.end =  moment(event.start).add(17, 'hours');
            	 event.start =  moment(event.start).add(8, 'hours');
            }else if (draggedEvent.name.includes('上午')) {
            	 event.end =  moment(event.start).add(12, 'hours');  
             event.start =  moment(event.start).add(8, 'hours');
            }else if (draggedEvent.name.includes('下午')) {
            	 event.end =  moment(event.start).add(17, 'hours');    	
            	 event.start =  moment(event.start).add(13, 'hours');
            	}else if (draggedEvent.name.includes('晚上')) {
            	 event.end =  moment(event.start).add(23, 'hours');    	
            	 event.start =  moment(event.start).add(18, 'hours');
            	}else {		
             event.start =  moment(event.start).add(8, 'hours');
              openDialog(event);
              return 0;
            }
            
            	if (event.eventable_type != "Appointment") {
        		
        	   event.title = "可預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour();
        	}else{
        		
        		 event.title = "預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour();
        	}
            
            
            	
            	 if ( !event.id  ){
    				createEvent(event); 
  				console.log('add mode')
  			}else{
  				updateEvent(event); 
  				console.log('edit mode')
  			}
  }
  
  const newEvent = event => {
    let idList = events.map(a => a.id)
    let newId = Math.max(...idList) + 1
    let hour = {
      id: newId,
      title: event.title,
      allDay: event.isAllDay,
      start: event.start,
      end: event.end,
    }
    setEvents(events.concat([hour]));
   
  }
  
  const handleClick = event => {
    console.log('Click happened title = ' + event.title);
  }
  
  
  const DeclareStateVar = (event) => {
  
  const [id, setId] = useState(event.id);
  const [title, setTitle] = useState(event.title ? event.title : "新預約" );
  const [allDay, setAllDay] = useState(event.allDay ? true : false);
  const [start, setStart] = useState(event.start ? moment(event.start).format("yyyy-MM-DDThh:mm") : (moment().add(1, 'days').format("yyyy-MM-DDThh:mm"))   );
  const [end, setEnd] = useState( event.end ? moment(event.end).format("yyyy-MM-DDThh:mm") : (moment().add(1,'days').add(1,'hour').format("yyyy-MM-DDThh:mm")) );

  let update_event = {
      id: event.id,
      title: title,
      allDay: allDay,
      start: start,
      end: end,
    }
  
    
  return (
    <>
     
  <List form noHairlinesMd >
  
   <ListInput
    label="標題"
    type="textarea"
    resizable
    placeholder="Bio"
    value={title} onChange={e =>{setTitle(e.target.value) } }
  > 
  </ListInput>
  
   {!allDay &&
  <ListInput
    label="Birthday"
    type="datetime-local"
    placeholder="Please choose..."
    value={start} onChange={e =>{(e.target.value < end) ? setStart(e.target.value) : (setEnd(e.target.value) || setStart(e.target.value))} }
  >
   
  </ListInput>
}
  {!allDay &&
  <ListInput
    label="Date time"
    type="datetime-local"
    placeholder="Please choose..."
    value={end} onChange={e =>{ (e.target.value > start) ? setEnd(e.target.value) : setEnd(start)  } }
  > 
 
  </ListInput>
 }
    <ListItem checkbox title="全天" name="checkbox"  checked={allDay} onChange={e =>{setAllDay(e.target.checked) }}/>
</List>


 <Block strong>
        <Row>
          
          <Col>
           {id &&
<Button fill round onClick={ e => {updateEvent(update_event)}}>修改</Button>
}
{!id &&
<Button fill round onClick={ e => {createEvent(update_event)}}>新增</Button>
}
          </Col>
          <Col>
            <Button fill round onClick={ e => {f7ready((f7) => {f7.dialog.close();})}}>離開</Button>
          </Col>
        </Row>
      </Block>
    </>
  );
}
 
  const onSelectSlot = ({ start, end, action }) => {
    selectTimeout && window.clearTimeout(selectTimeout);
       
    selectTimeout = setTimeout(() => {
      console.log("onSelectSlot: ", { start, end, action });
      
      const event = {
      title: props.title ? props.title : (props.mode == 'Consultant' ? '可預約' : '預約'),
      start,
      end,
      allDay: false,
    }
    
      openDialog(event);
      
    }, 250);
  };

  const onSelectEvent = (event) => {
    selectTimeout && window.clearTimeout(selectTimeout);
    if(event.eventable_type == "Consult"){ 
     openDialog(event);
     }else if (event.client_id == props.customer_id) {
     openDialog(event);
     } else if (props.mode == 'Consultant') {
     openDialog(event);
     }
    selectTimeout = setTimeout(() => {
      console.log("onSelectEvent: ", event);
    }, 250);
  };

  const onDoubleClickEvent = (event) => {
  //  selectTimeout && window.clearTimeout(selectTimeout);
   if(event.eventable_type == "Consult"){ 
     openDialog(event);
   }else if (event.client_id == props.customer_id) {
     openDialog(event);
   } else if (props.mode == 'Consultant') {
     openDialog(event);
  }
  

    selectTimeout = setTimeout(() => {
      console.log("onDoubleClickEvent: ", event);
    }, 250);
  };
  
  const openDialog = event =>{
  	   console.log("onOpenDialog: ", event);
  	    console.log("onOpenDialog: event.id", event.id);
     if ( !event.id  ){
  	
  	console.log('add mode')
  }else{
  	
  	console.log('edit mode')
  }
    
//    const updatedCounters = {
//      ...counters,
//      [draggedEvent.name]: counters[draggedEvent.name] + 1,
//    }
    setDraggedEvent(null);
//    setCounters(updatedCounters );
//    newEvent(event)
    
       f7ready((f7) => {
    var now = new Date();
  	var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      var weekLater = new Date().setDate(today.getDate() + 7);
      var origin_calendar = [];
      var origin_calendar_ids = [];
  	  var delete_string =  '<a href="#" class="link sheet-close delete popover-close" style="float:right">刪除</a>';
   var today = new Date();
  
      var pickerInline = f7.picker.create({
     //   containerEl: '#demo-picker-date-container',
     //   inputEl: '#demo-picker-date',
        openIn: 'auto',
   //     toolbar: true,
        rotateEffect: true,
        closeByOutsideClick:true,
   //     value: [
   //       today.getHours(),
   //       today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
   //     ],
        formatValue: function (values, displayValues) {
          return  values[0] + ':' + values[1];
        },
        renderToolbar: function () {
        	
        	 if (event.client_id != props.customer_id) {
            delete_string = '';
          }
  
        	  	 if ( !event.id  ){
         return '<div class="toolbar picker-tool-bar" style="width:80%;margin-left: auto;margin-right: auto">' +
            '<div class="toolbar-inner">' +
            '<div class="left block row">' +
            '<a href="#" class="link sheet-close popover-close">取消</a>' +
            '</div>' +
            '<div class="center">' +
            '請選擇時間' +
            '</div>' +
            '<div class="right">' +
            '<a href="#" class="link ">確認</a>' +
            '</div>' +
            '</div>' +
            '</div>';
            }else{
            	  return '<div class="toolbar picker-tool-bar" style="width:80%;margin-left: auto;margin-right: auto">' +
            '<div class="toolbar-inner">' +
            '<div class="left block row">' +
            '<a href="#" class="link sheet-close popover-close">取消</a>' +
            delete_string  +
            '</div>' +
            '<div class="center">' +
            '請選時間' +
            '</div>' +
            '<div class="right">' +
            '<a href="#" class="link ">確認</a>' +
            '</div>' +
            '</div>' +
            '</div>';
            	
            };
        },
        cols: [
        
          // Hours
          {
            values: (function () {
              var arr = [];
              var count = 0;
              var consult_start;
              var consult_end;
              var booked_hours = [];
              const _start = event.start;
              const _end = event.end;
              const consultEventsForThisDay = events.filter(
              event => moment(event.start).startOf('day').format('YYYY/MM/DD') ==  moment(_start).startOf('day').format('YYYY/MM/DD') && (event.eventable_type != "Appointment")
              );
              consult_start = Math.min.apply(Math,consultEventsForThisDay.map(event=>moment(event.start).hour()));
              consult_end = Math.max.apply(Math,consultEventsForThisDay.map(event=>moment(event.end).hour()));
              
              const clientEventsForThisDay = events.filter(
              event => event.start >= _start && event.start <= _end && (event.eventable_type == "Appointment")
              );
              booked_hours = clientEventsForThisDay.map(event=>moment(event.start).hour());
             console.log("client events count=>" + (clientEventsForThisDay.map(event=>event.start) ? clientEventsForThisDay.map(event=>event.start).length : "0") ); 
             console.log("consultant events count=>" + (consultEventsForThisDay.map(event=>event.start) ? consultEventsForThisDay.map(event=>event.start).length : "0") );
              console.log("events booked hours=>" + booked_hours);
                console.log("consult start & end =>" + consult_start + "=" + consult_end);
              
              for (var i = 0; i <= 23; i++) { 
              	if (props.mode == 'Customer' && event.eventable_type != "Appointment"){
                   if (moment(event.start).hour() <= i &&  moment(event.end).subtract(1,'hours').hour() >= i && !booked_hours.includes(i))
              	   {
              	 	 arr.push(i); 
             	    }else {
             	 		
             	    }
             	 }else if (props.mode == 'Customer' && event.eventable_type == "Appointment"){
             	 	if (consult_start <= i &&  consult_end >i )
              	   {
              	 	 arr.push(i); 
             	    }else {
             	 		
             	    }
             	 	
             	 	
             	 	
             	 }else {
             	 	
             	 	 arr.push(i); 
             	 }
              	 
               }
              if (arr.length == 0) {
              	arr = ['預約已滿！']
              }	 
              return arr;
             
            })(),
          },
          // Divider
          {
            divider: true,
            content: ':'
          },
          // Minutes
          {
            values: (function () {
              var arr = ['0','15','30','45'];
            //  for (var i = 0; i <= 59; i++) { arr.push(i < 10 ? '0' + i : i); }
              return arr;
            })(),
          }
        ],
        on: {
        	
        	opened: function (picker) {
          
            $('.picker-tool-bar .right .link').on('click', function () {
            
            if (props.mode == 'Consultant') {
            	event.start =moment(event.start).set('hour',picker.value[0]).set('minute',picker.value[1]).format();
            	event.end =  moment(event.start).set('hour',picker.value[0]).set('minute',picker.value[1]).add(1, 'hours');
            if (event.end == null) {
          //  event.end =  moment(event.start).set('hour',picker.value[0]).set('minute',picker.value[1]).add(1, 'hours');
            }
            	 if ( !event.id  ){
    				createEvent(event); 
  				console.log('add mode')
  			}else{
  				updateEvent(event); 
  				console.log('edit mode')
  			}
            }
            else{
            		if(event.eventable_type == "Consult"){ 
                console.log('picker value 0 ' + picker.value[0]);
				event.start =moment(event.start).set('hour',picker.value[0]).set('minute',picker.value[1]).format();  // moment( event.start.toDate();
			    event.end =  moment(event.start).set('hour',picker.value[0]).set('minute',picker.value[1]).add(1, 'hours');
				console.log('event start' + event.start)
				f7.views.main.router.navigate("/consults/" + props.consult_id + "/appointment/" + event.start  + "/" ,
				     {reloadCurrent: false,
					 ignoreCache: true})
            		}else{
          		//	 updateEvent(event);
  				f7.views.main.router.navigate("/consults/"+ props.consult_id + "/edit_appointment/" + event.eventable_id + "/event/" + event.id + "/",
  				 {reloadCurrent: false,
					 ignoreCache: true})
          		}
     		}
             
             picker.close();
            });
            
           
            
            $('.picker-tool-bar .left').on('click', function () {
            	console.log("pickercloseclick ");
         //   picker.close();
      f7.picker.close('#demo-picker-date-container');
            });
            
                $('.picker-tool-bar .delete').on('click', function () {
            	console.log("pickercloseclick ");
            	
       
  if (event.client_id == props.customer_id) {
      deleteEvent(event);
  }
  
            	
            	
        
      f7.picker.close('#demo-picker-date-container');
            });
            
          	},
          
        	
          change: function (picker, values, displayValues) {
       //     var daysInMonth = new Date(picker.value[2], picker.value[0] * 1 + 1, 0).getDate();
       //     if (values[1] > daysInMonth) {
       //       picker.cols[1].setValue(daysInMonth);
      //      }
      //	picker.getValue();	
     // 	  console.log('on change picker value 0 ' + picker.value[0]);
          },
        }
      });
  
  
  
  
      pickerInline.open();
       pickerInline.setValue([moment(event.start).hour(),moment(event.start).minute()]);
//	calendarRange.open();    
    
    })
      
  }

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const updatedEvent = { ...event, start, end, allDay };

    setEvents((prevEvents) => {
      const filtered = prevEvents.filter((it) => it.id !== event.id);
      return [...filtered, updatedEvent];
    });
     updateEvent(updatedEvent); //added by TC
 //    alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  };

  const resizeEvent = ({ event, start, end }) => {
    setEvents((prevEvents) => {
      const filtered = prevEvents.filter((it) => it.id !== event.id);
      return [...filtered, { ...event, start, end }];
    });
 
 updateEvent({ ...event, start, end }); //added by TC
   // alert(`${event.start} was resized to ${start}-${end}`)
  };

  const onKeyPressEvent = ({ event, ...other }) => {
    console.log("[onKeyPressEvent] - event", event);
    console.log("[onKeyPressEvent] - other", other);
  };

  const onDragStart = ({ event, action }) => {
    const { id } = event;
    if (id === 5) {
      return false;
    }
    console.log(`onDragStart: ${action}`, event);
  };

  const onSelecting = (range) => {
    console.log("[onSelecting] range: ", range);
  };
   
   React.useEffect(() => {
      setCustomerID(props.customer_id);
      getEvents();
   //   getAppointments();
   //   getConsults();
  }, [props.customer_id])
  
  const getConsults = ()=> {
  	 const eventsUrl = '/consults/consultant_index.json?product_id=6&'+'customer_id='+ props.customer_id ;
  	  const requestMetadata = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
     //   body: JSON.stringify({events: this.state.events})
    };
    
   fetch(eventsUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    jsonData.events.forEach((event, index) => {
  jsonData.events[index].start = moment( jsonData.events[index].start).toDate();
   jsonData.events[index].end = moment( jsonData.events[index].end).toDate();
});    	 
     setEvents(jsonData.events);
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
   
  }
  
  
  
  
  
   const getEvents = ()=> {
  	 const eventsUrl = "/events.json?consult_id=" + props.consult_id + '&customer_id='+ props.customer_id ;
  	  const requestMetadata = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
     //   body: JSON.stringify({events: this.state.events})
    };
    
   fetch(eventsUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    jsonData.events.forEach((event, index) => {
  jsonData.events[index].start = moment( jsonData.events[index].start).toDate();
   jsonData.events[index].end = moment( jsonData.events[index].end).toDate();
});    	 
     setEvents(jsonData.events);
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
   
  }
  
  const getAppointments = ()=> {
  	 const eventsUrl = '/appointments.json?'+ 'customer_id='+ props.customer_id ;
  	  const requestMetadata = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
     //   body: JSON.stringify({events: this.state.events})
    };
    
   fetch(eventsUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    jsonData.events.forEach((event, index) => {
  jsonData.events[index].start = moment( jsonData.events[index].start).toDate();
   jsonData.events[index].end = moment( jsonData.events[index].end).toDate();
});    	 

     setEvents(prevState => {
  // Object.assign would also work
  return [...prevState , ...jsonData.events] ;
});
     	
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
   
  }
  
  
  
  const createEvent = (event)=> {
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const recipeUrl = '/events.json?'+'customer_id=' + customerId + '&mode='+ props.mode + '&consult_id=' + props.consult_id;
    const postBody = {
        type: "hot",
        limit: 10
    };
   // event.start =moment(event.start).set('hour', 9 );  // moment( event.start.toDate();
  //  event.end =  moment(event.start).set('hour',17);
    const requestMetadata = {
        method: 'POST',
        headers: {
        	    "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({events: [event]})
    };
  fetch(recipeUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    jsonData.events.forEach((event, index) => {
  jsonData.events[index].start = moment( jsonData.events[index].start).toDate();
   jsonData.events[index].end = moment( jsonData.events[index].end).toDate();
});    	 
     setEvents(jsonData.events);
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
    
    
    f7ready((f7) => {
    f7.dialog.close();
    });
  }
  
   const updateEvent = (event)=> {
   	
   	  	if (event.eventable_type != "Appointment") {
        		
        	   event.title = "可預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour();
        	}else{
        		
        		 event.title = "預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour()
        	}
   	
   	
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const recipeUrl = '/events/' + event.id + '.json?' + 'customer_id=' + customerId + '&consult_id=' + props.consult_id;
    const postBody = {
        type: "hot",
        limit: 10
    };
    const requestMetadata = {
        method: 'PATCH',
        headers: {
        	    "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({events: [event]})
    };
  fetch(recipeUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    jsonData.events.forEach((event, index) => {
  jsonData.events[index].start = moment( jsonData.events[index].start).toDate();
   jsonData.events[index].end = moment( jsonData.events[index].end).toDate();
});    	 
     setEvents(jsonData.events);
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
    
    
    f7ready((f7) => {
    f7.dialog.close();
    });
  }
  
  
  
  
    const deleteEvent = (event)=> {
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const recipeUrl = '/events/' + event.id + '.json?'+'customer_id=' + customerId + '&consult_id=' + props.consult_id;
    const postBody = {
        type: "hot",
        limit: 10
    };
    const requestMetadata = {
        method: 'DELETE',
        headers: {
        	    "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({events: [event]})
    };
  fetch(recipeUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    jsonData.events.forEach((event, index) => {
  jsonData.events[index].start = moment( jsonData.events[index].start).toDate();
   jsonData.events[index].end = moment( jsonData.events[index].end).toDate();
});    	 
     setEvents(jsonData.events);
    }).catch((err) => {
    console.log('錯誤:', err);
    });  
  }
  
  // example implementation of a wrapper
  const CURRENT_DATE = moment().toDate();
const ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: value < CURRENT_DATE ? '' : '',
        },
    });
  
  
  const updateEvents = ()=> {
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const recipeUrl = '/events.json?'+'customer_id=' + customerId;
    const postBody = {
        type: "hot",
        limit: 10
    };
    const requestMetadata = {
        method: 'POST',
        headers: {
        	    "X-CSRF-Token": csrfToken, // 👈👈👈 Set the token
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({events: events})
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.json())
        .then(recipes => {
            this.setState({ events });
        });
  }
  
  
   
  return (	
    <div className="App2" style={{ height: "100vh" }}>
    <Card className="examples--header" style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <h4 style={{ color: 'gray', width: '100%' }}>
             
            </h4>  
            { (props.mode != 'Customer' && (Framework7.device.desktop )) ? Object.entries(counters).map(([name, count]) => (
              <div
                style={{
                  border: '2px solid gray',
                  borderRadius: '4px',
                  width: '100px',
                  margin: '10px',
                }}
                draggable="true"
                key={name}
                onDragStart={() =>
                  handleDragStart({ title: formatName(name, count), name })
                }
              >
                {formatName(name, count)}
              </div>
            ))
            : "（請點選以下日期後，選擇預約時間）" }
            <div>
           
            </div>
          </div>

          
        </Card>

    
    
          <Block>
         <Row>
         	<Col width="20">
         	
         		
         	</Col>
         	<Col width="20">
         	{ props.mode == 'Consultant' ?
         			''
         	: ""
         	}
         	</Col>
         	<Col width="60">
         	</Col>
         </Row>
         </Block>
<div className="block block-strong medium-inset no-padding no-margin margin-bottom">
        <div id="demo-picker-date-container"></div>
      </div>
      
      { props.mode == 'Consultant' ?
      <Calendar
        {...{
          events,
          date,
          onNavigate,
          view,
          onView,
          onSelectSlot,
          onSelectEvent,
          onSelecting,
          onDoubleClickEvent,
          onKeyPressEvent,
          onDropFromOutside
        }}
        messages={{'today': "今天", "previous":'上一頁', "next":"下一頁","day":"日","month":"月","week":"周","myweek":"當周行程" }}
        showMultiDayTimes
        longPressThreshold={10}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        getNow={now}
        {...accessors}	
        selectable="ignoreEvents"
        components={{
                // you have to pass your custom wrapper here
                // so that it actually gets used
                dateCellWrapper: ColoredDateCellWrapper,
        }}
        eventPropGetter={(event, start, end, isSelected) => {
        	if (event.eventable_type != "Appointment") {
        		
        	   event.title = "可預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour();
        	}else{
        		
        		 event.title = "預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour() + " " + event.customer_name;
        	}
        	
      let newStyle = {
        backgroundColor: (event.status == "update") ? "red" : ( (event.status == "schedule") ? "" : "lightblue"),
  //      color: 'black',
  //      borderRadius: "0px",
  //      border: "none",
      };

      if (event.allDay){
        newStyle.backgroundColor = "lightgreen"
      }

      return {
        className: "",
        style: newStyle
      };
    }
  }
      />
      :    // User mode can not move or drop
       <Calendar
        {...{
          events,
          date,
          onNavigate,
          view,
          onView,
    //      onSelectSlot,
         onSelectEvent,
    //      onSelecting,
          onDoubleClickEvent,
    //      onKeyPressEvent,
    //      onDropFromOutside
        }}
         messages={{'today': "今天", "previous":'上一頁', "next":"下一頁","day":"日","month":"月","week":"周","myweek":"當周行程" }}
        showMultiDayTimes
        longPressThreshold={10}
    //    onEventDrop={moveEvent}
    //    onEventResize={resizeEvent}
        getNow={now}
        {...accessors}
        selectable="ignoreEvents"
        components={{
                // you have to pass your custom wrapper here
                // so that it actually gets used
                dateCellWrapper: ColoredDateCellWrapper,
        }}
        eventPropGetter={(event, start, end, isSelected) => {
        		if (event.eventable_type != "Appointment") {
        		
        	   event.title = "可預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour();
        	}else{
        		
        		 event.title = "預約 " + moment(event.start).hour() + "-" +  moment(event.end).hour()  ;
        	}
        	
        	
      let newStyle = {
        backgroundColor: (event.status == "update") ? "red" : ( (event.status == "schedule") ? "" : "lightblue"),
  //      color: 'black',
   //     borderRadius: "0px",
   //     border: "none",
      };

      if (event.allDay){
        newStyle.backgroundColor = "lightgreen"
      }

      return {
        className: "",
        style: newStyle
      };
    }
  }
      />
      
      }
      
    </div>
  );
};

export default MyCalendar