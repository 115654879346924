// Determine theme depending on device
// Import Framework7 Core
/* my-app.js */
// Import React
import React from 'react';
 
// Import ReactDOM
import ReactDOM from 'react-dom';

import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// Import Framework7 React
import Framework7React from 'framework7-react';

import { f7ready } from 'framework7-react';

// Init plugin
import { Device, Support } from 'framework7';


import ReactOnRails from 'react-on-rails';


import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import Index from '../bundles/HelloWorld/components/SlideShow';
import TutorialIndex from '../bundles/HelloWorld/components/TutorialSlideShow';
import ConsultIndex from '../bundles/HelloWorld/components/ConsultSlideShow';

import MyCalendar from '../bundles/HelloWorld/components/BigCalendar';
import JitsiMeet from '../bundles/HelloWorld/components/JitsiMeet';
import Master from '../bundles/HelloWorld/components/Master';

ReactOnRails.register({ HelloWorld });
ReactOnRails.register({ Index });
ReactOnRails.register({ TutorialIndex });
ReactOnRails.register({ ConsultIndex });

ReactOnRails.register({ MyCalendar });
ReactOnRails.register({ JitsiMeet });
ReactOnRails.register({ Master });

Framework7.use(Framework7React ) 

var isAndroid = Framework7.device.android === true;
var isIos = Framework7.device.ios === true;
 

// Define Dom7

import $$ from 'dom7'
// Add CSS Styles
//if (isAndroid) {
    $$('head').append(
    '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/framework7/5.7.14/css/framework7.bundle.min.css">' +
      '<link rel="stylesheet" href="/framework7/dist/css/framework7-icons.css">' + 
       '<link rel="stylesheet" href="/css/my.css">'
    	 // '<link rel="stylesheet" href="/framework7/dist/css/framework7.ios.min.css">' +
      //  '<link rel="stylesheet" href="/framework7/dist/css/framework7.ios.colors.min.css">' +
      //  '<link rel="stylesheet" href="/framework7/dist/css/my-app.ios.css">'
    );
//}
//else {
//    $$('head').append(
//    	'<link rel="stylesheet" href="/framework7/dist/css/framework7.bundle.min.css">' +
//    	 '<link rel="stylesheet" href="/framework7/dist/css/framework7-icons.css">' +
//    	  	 '<link rel="stylesheet" href="/css/my.css">'
    //    '<link rel="stylesheet" href="/framework7/dist/css/framework7.ios.min.css">' +
    //    '<link rel="stylesheet" href="/framework7/dist/css/framework7.ios.colors.min.css">' +
    //    '<link rel="stylesheet" href="/framework7/dist/css/my-app.ios.css">'
//    );
//}
 
// Change Through navbar layout to Fixed
if (isAndroid) {
    // Change class
    $$('.view.navbar-through').removeClass('navbar-through').addClass('navbar-fixed');
    // And move Navbar into Page
    $$('.view .navbar').prependTo('.view .page');
}
 

/* my-app.js */



// Import Main App component
import App from './hello_react.jsx';

// Mount React App
ReactDOM.render(
  React.createElement(App),
  document.getElementById('app')
)
	const Hello = props => (
  <div className='react-app-wrapper'>
   
    <h5 className='hello-react'>
      {props.message} {props.name}!
    </h5>
  </div>
)

