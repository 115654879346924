import React , { Fragment , useState ,useEffect} from 'react';
import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";
import "react-multi-carousel/lib/styles.css";
import UAParser from "ua-parser-js";
import WithScrollbar from "./WithScrollbar";
import WithVideo from "./WithVideo";
import Section from "./Section";

import { Chip ,Icon, Link , Badge } from 'framework7-react';
import { Device, Request, Support } from 'framework7';
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 12,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    paritialVisibilityGutter: 30
  }
};

var deviceType = 'Desktop';

if (Device.android || Device.ios){
	
	deviceType = 'mobile';
}



// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const Simple = ( {props} ) => {

const [images, setImages] = useState([]);


 useEffect(() => {
   const eventsUrl = '/sessions/master_tasks?' + (props.task_id != null ?  'task_id=' + props.task_id : "");
  	  const requestMetadata = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
     //   body: JSON.stringify({events: this.state.events})
    };
    
   fetch(eventsUrl, requestMetadata)
        .then((recipes) => {
        	return recipes.json();
        }).then((jsonData) => {
    	  
     setImages(jsonData.data);
    }).catch((err) => {
    console.log('錯誤:', err);
    });     
   
  }, []);
 

  return (
	
    <Carousel 
      itemClass="carousel-item-padding-40-px"
      deviceType={deviceType}
      responsive={responsive}
      infinite={true}
      autoPlay={ false}
      autoPlaySpeed={5000}
      swipeable={true}
      removeArrowOnDeviceType={'mobile'}
  //    arrows={true}
    >
      {images.map(o => {
        return (
			<Link href={o.url} key={o.url}  force={true} reloadCurrent={true} ignoreCache={true}>
			<Chip text={o.name } mediaBgColor="green" >&nbsp;
			{o.count > 0  && <Badge color="red">{o.count}</Badge>}
        </Chip>
        </Link>
        );
      })}
     
    </Carousel>
    
  );
};



const Master = (props ) => {
  return (
    <Fragment>
      <Section>
        <Simple props={props} />
      </Section>
     
    </Fragment>
  );
};

Master.getInitialProps = ({ req }) => {
  let userAgent;
  if (req) {
    userAgent = req.headers["user-agent"];
  } else {
    userAgent = navigator.userAgent;
  }
  const parser = new popsrser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || "desktop";
  return { deviceType };
};
export default Master;

